const generateTableOfContents = (container, titleTags) => {
    const titles = container.querySelectorAll(titleTags);
    const tableOfContents = document.createElement('ul');
    tableOfContents.classList.add('table-of-contents');

    titles.forEach(title => {
        const listItem = document.createElement('li');
        const link = document.createElement('a');

        link.setAttribute('href', `#${title.id}`);
        link.innerHTML = title.innerHTML;
        listItem.appendChild(link);
        tableOfContents.appendChild(listItem);
    });
    container.insertAdjacentElement('afterbegin', tableOfContents);
};

const anchorContainers = document.querySelectorAll('.js-generated-table-of-contents');
anchorContainers.forEach(container => {
    const titleTags = container.getAttribute('data-anchor-tags').replace(/\s/g, ' ');
    generateTableOfContents(container, titleTags);
});
