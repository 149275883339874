import '../common/index.js';
import './static.css';
import '../pages/static/privacy-policy.css';
import '../pages/static/terms-of-service.css';
import '../pages/static/subscription_terms.css';
import '../pages/static/index.css';
import '../components/card-grid/index.js';
import '../components/my-aid-card/index.js';
import '../components/aid-button/button-bar/index.js';
import '../components/aid-button/button-link/index.js';
import '../pages/static/table-of-contents-generator.js';
import SiteList from "~/components/SiteList.svelte";
import {register} from "../common/svelte_manager.js";
register({SiteList})